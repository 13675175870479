<template>
  <div class="private_wrap" >
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
export default {
  name: 'private',
  data() {
    return {

    }
  }
}
</script>
